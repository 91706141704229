const INTENT = 4;

/**
 * Efficiently convert a structured CSS JS object into a plain CSS string.
 */
function objectToCss(rules) {
  let level = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  const intentStr = " ".repeat(level * INTENT);
  return [...rules.keys()].map(selector => {
    const definition = rules.get(selector);
    const thisRules = Object.keys(definition);
    const result = thisRules.map(rule => {
      const ruleValue = definition[rule];

      // Nested scope
      if (typeof ruleValue === "object") {
        const m = new Map();
        m.set(rule, ruleValue);
        return `${intentStr}${objectToCss(m, level + 1)}\n`;
      } else {
        return `${intentStr}${rule}: ${ruleValue};\n`;
      }
    }).join("");
    return `${selector} {\n${result}${level > 1 ? " ".repeat((level - 1) * INTENT) : ""}}`;
  }).join("\n");
}
export { objectToCss };